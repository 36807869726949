.Heronft_bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/hero_nft.png");
  /* padding-top: 100px; */
  /* background-position: 100% 237%; */
  background-position: center;
  height: 435px;
}

.nfthero {
  width: 285px;
  height: 285px;
}

.nft_banner:after {
  content: "";
  position: absolute;
  width: 397px;
  height: 683px;
  top: 174px;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/nftherorightbg.png");
}

.nft_banner {
  padding-bottom: 100px;
  margin-top: -100px;
}

.nft_banner h6 {
  color: #a266d8;
  text-align: center;
  font-family: VastagoGrotesk-Regular;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nft_banner h1 {
  color: #12101e;
  text-align: center;
  font-family: VastagoGrotesk-Regular;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nft_banner p {
  color: #12101e;
  /* width: 1228px; */
  text-align: center;
  font-family: VastagoGrotesk-Regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.NFT_PAGE .Faq_box:before {
  width: 313px;
  height: 314px;
  background-size: contain;
  left: -4px;
  transform: rotate(358deg);
  z-index: 99999;
  background-image: url("./../../assets/images/nft_faq_right.png");
}

.NFT_PAGE .Faq_box:after {
  right: -1px;
  width: 313.383px;
  height: 313.383px;
  background-size: contain;
  transform: rotate(-7.115deg);
  z-index: 99999;
  transform: scaleX(-1);
  background-image: url("./../../assets/images/nft_faq_right.png");
}

.NFT_PAGE .footer_bg:after {
  top: -336%;
  width: 302.167px;
  height: 666.919px;
  background-size: contain;
  background-image: url("./../../assets/images/nftfleft.png");
}

.NFT_PAGE .footer_bg:before {
  top: -336%;
  width: 276.167px;
  height: 666.919px;
  background-size: contain;
  background-image: url("./../../assets/images/nftfright.png");
}

.nft_box_product img {
  border-radius: 20px;
}

.nft_product_box {
  padding-bottom: 100px;
}

.ntf_pagination .page-item {
  margin: 0px 3px;
}

.ntf_pagination .page-item.active .page-link {
  background: #a266d8;
  color: #fff;
}

.ntf_pagination .page-item .page-link {
  color: #000;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background: #fff;
}

@media screen and (min-width: 4000px) and (max-width: 5000px) {
  .Heronft_bg {
    height: 1000px;
  }
}

@media screen and (min-width: 3000px) and (max-width: 4000px) {
  .Heronft_bg {
    height: 800px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 3000px) {
  .Heronft_bg {
    height: 700px;
  }
}

@media screen and (max-width: 923px) {
  .NFT_PAGE .Faq_box:after {
    display: none;
  }

  .NFT_PAGE .Faq_box:before {
    display: none;
  }
}
