/* .Hero_bg {
  padding-top: 100px;
  height: 140vh;
} */

.banner_content h6 {
  color: #fff;
  font-family: VastagoGrotesk-Regular;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.banner_content h1 {
  color: #fff;
  font-family: VastagoGrotesk-Medium;
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner_btns a {
  padding: 20px 60px;
  border-radius: 100px;
  text-decoration: none;
  display: inline-block;
  color: #f4f7ee;
  text-align: center;
  text-shadow: 0px 2.3671875px 0px #14110c;
  font-family: Supercell-Magic;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 40px;
}

.banner_content {
  /* padding-top: 100px; */
}

.bg-btn {
  border: 2.367px solid #000;
  background: linear-gradient(180deg, #a266d8 0%, #d924f1 100%);
}

.outline-btn {
  border: 2.367px solid #fff;
  background: transparent;
}

.Hero_bg_box {
  position: relative;
}

.bg_cloud {
  position: relative;
  top: -150px;
}

.analytic_box h1 {
  color: #a266d8;
  font-family: VastagoGrotesk-Regular;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%;
}

.analytic_box h6 {
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.5%;
}

.anlytics_bl:after {
  content: "";
  position: absolute;
  top: 10px;
  right: 57px;
  width: 3px;
  height: 89px;
  background: #d6d6d6;
}

.intro_box h6 {
  color: #a266d8;
  font-family: VastagoGrotesk-Regular;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.intro_box h2 {
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.intro_box p {
  color: #12101e;
  font-family: VastagoGrotesk-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.game_intro_box h2 {
  color: #fff;
  font-family: VastagoGrotesk-Medium;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.game_intro_box p {
  color: #964b00;
  font-family: VastagoGrotesk-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.game_intro_box a {
  padding: 20px 60px;
  border-radius: 100px;
  text-decoration: none;

  color: #f4f7ee;
  text-align: center;
  text-shadow: 0px 2.3671875px 0px #14110c;
  font-family: Supercell-Magic;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.theme_btn_fill {
  outline: 2.367px solid #000;
  background: linear-gradient(180deg, #a266d8 0%, #d924f1 100%);
  border-top: 2.367px solid #f7fde7;
  filter: blur(0.39453125px);
}

.game_intro {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-image: url("./../../assets/images/redeining.jpg");
  padding-top: 20%;
  padding-bottom: 20%;
}

.theme_btn_fill2 {
  background: linear-gradient(180deg, #2ad4b3 0%, #09baba 100%);
  outline: 2.367px solid #000;
  border-top: 2.367px solid #f7fde7;
  filter: blur(0.39453125px);
  margin-top: 14px;
}

.Game_into_image2 img {
  border-radius: 43px;
  border: 6px solid #0ff;
}

.w-80 {
  width: 80%;
}

/* .Game_box {
  padding-top: 120px;
} */
.Game_box2 {
  margin: 100px 0px 200px;
}

.heading_box h6 {
  color: #a266d8;
  font-family: VastagoGrotesk-Medium;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading_box h1 {
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.road_step h3 {
  color: #b3b3b3;
  text-align: center;
  font-family: VastagoGrotesk-Medium;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 67px;
  width: 66px;
  height: 66px;
  border-radius: 100%;
  border: 2px solid #6b6b6b;
}

.road_box {
  padding-bottom: 0px;
}

.road_step h3.bars.active {
  color: #a266d8;
  border: 2px solid #a266d8;
}

.road_step h3.bars.active::after {
  background: #a266d8;
}

.road_box:hover .road_step h3 {
  color: #a266d8;
  border: 2px solid #a266d8;
}

.road_box:hover h3.bars::after {
  background: #a266d8;
}

.road_step h3.bars::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 165px;
  top: -51px;
  background: #f1f1f1;
  transform: rotate(90deg);
  left: 200px;
}

.road_deatils {
  padding: 0px 20px;
  opacity: 0.5;
}

.road_deatils h2 {
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.road_deatils:hover {
  opacity: 1;
}

.road_deatils.active {
  opacity: 1;
}

.road_deatils p img {
  margin-right: 8px;
}

.road_deatils p {
  display: flex;
  justify-content: start;
  align-items: start;
  color: #12101e;
  font-family: VastagoGrotesk-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.road_box:after {
  content: "";
  position: absolute;
  width: 397px;
  height: 683px;
  top: 174px;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/roadright.png");
}

.road_box:before {
  content: "";
  position: absolute;
  width: 243px;
  height: 571px;
  top: -4%;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/roadleft.png");
}

.tokenomic_box {
  padding-bottom: 200px;
  padding-top: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/tokenbanner.jpg");
}

.team_box {
  padding-top: 100px;
  padding-bottom: 100px;
}

.team_small_box:hover {
  filter: drop-shadow(4px 9px 21px rgba(0, 0, 0, 0.1));
}

.team_small_box {
  padding: 37.5px 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("./../../assets/images/teambg.svg");
}

.team_small_box::after {
  content: "";
  position: absolute;
  background-image: url("./../../assets/images/teamaddons.svg");
  width: 52px;
  height: 59px;
  top: 1px;
  left: 0;
  background-repeat: no-repeat;
}

.team_small_box:hover {
  background-image: url("./../../assets/images/teambghover.svg");
}

.team_img {
  width: 122px;
  height: 122px;
  border-radius: 122px;
}

.team_small_box h6 {
  color: #12101e;
  text-align: center;
  font-family: VastagoGrotesk-Medium;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.team_small_box p {
  color: #a266d8;
  text-align: center;
  font-family: VastagoGrotesk-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.team_section {
  background-position: 0% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./../../assets/images/teambgg.png");
}

.join_box {
  padding: 100px 0px 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./../../assets/images/joinbg.jpg");
}

.join_box .banner_btns a {
  width: 265px;
  height: 91px;
  line-height: 91px;
  font-size: 19px;
  padding: 0px;
}

.Faq_box {
  padding-top: 100px;
  padding-bottom: 100px;
}

#accordionExamplemine .accordion-item {
  margin-bottom: 20px;
  border-radius: 8px;
  background: #f6f6f6;
}

#accordionExamplemine .accordion-button {
  border-radius: 8px;
  background: #f6f6f6;
}

#accordionExamplemine .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#accordionExamplemine .accordion-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#accordionExamplemine .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#accordionExamplemine .accordion-item:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#accordionExamplemine .accordion-item {
  border: 0px;
}

#accordionExamplemine .accordion-header {
  color: #1e1e1e;
  font-family: VastagoGrotesk-Medium;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#accordionExamplemine .accordion-button::after {
  background-image: url("./../../assets/images/Plus.svg");
}

.Faq_box {
  overflow: hidden;
}

.Faq_box:after {
  content: "";
  position: absolute;
  width: 252px;
  height: 267px;
  transform: rotate(-10deg);
  top: 186px;
  right: -20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/faqright.gif");
}

.Faq_box:before {
  content: "";
  position: absolute;
  width: 252px;
  height: 267px;
  top: 25%;
  transform: rotate(-10deg);
  left: -59px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/faqleft.gif");
}

.footer_bg:after {
  content: "";
  filter: drop-shadow(31px 27px 91px rgba(0, 0, 0, 0.1));
  position: absolute;
  width: 357px;
  height: 717px;
  top: -338px;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/fl.png");
}

.footer_bg:before {
  content: "";
  position: absolute;
  width: 252px;
  height: 628px;
  top: -105%;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./../../assets/images/fr.png");
}

.press_box {
  padding-top: 100px;
  padding-bottom: 100px;
}

.press_heading_box h4 {
  color: #a266d8;
  text-align: center;
  font-family: VastagoGrotesk-Regular;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.press_heading_box a {
  color: #f4f7ee;
  text-align: center;
  text-shadow: 0px 2.3671875px 0px #14110c;
  font-family: Supercell-Magic;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 100px;
  background: linear-gradient(180deg, #2ad4b3 0%, #09baba 100%);
  outline: 2.367px solid #000;
  border-top: 2.367px solid #f7fde7;
  filter: blur(0.39453125px);
  text-decoration: none;
  padding: 12px 24px;
  display: inline-block;
}

.coin {
  left: 65%;
  bottom: -35px;
  position: absolute;
  z-index: -1;
}

.play_form {
  padding-top: 60px;
  padding-bottom: 60px;
  box-shadow: 0px 0px 19px 8px #b0b0b0;
  background: radial-gradient(circle at 100% 100%,
      #ffffff 0,
      #ffffff 3px,
      transparent 100px) 0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 10px) calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 16px) calc(100% - 10px) no-repeat,
    conic-gradient(#ff0000 0%,
      #ffff00 16.67%,
      #00ff00 33.33%,
      #00ffff 50%,
      #0000ff 66.67%,
      #ff00ff 83.33%,
      #ff0000 100%);
  --borderWidth: 5px;
  background: #fff;
  position: relative;
  border-radius: var(--borderWidth);
}


.play_form:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #ff0000, #ffff00, #ff0000, #00ffff, #0000ff, #ff00ff, #ff0000, #00ff00);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  -webkit-animation: animatedgradient 10s ease alternate infinite;
  animation: animatedgradient 10s ease alternate infinite;
  background-size: 300% 300%;
}

/* Keyframes for the rotation animation */

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.invert-1 {
  filter: invert(100%);
}

.tranform-input {
  transform: translateY(-50%);
  left: 20%;
}

.btn-active {
  background-color: #627eea;
  color: white !important;
}

.btn-active1 {
  background-color: #26a17b;
  color: white !important;
}

.btn-active2 {
  background-color: #26a17b;
  color: white !important;
}

.play_form .heading_box p {
  font-size: 24px;
}

.conversion_box p {
  position: relative;
  padding: 10px;
  border-radius: 26.5px;
  border: 1px solid #edd9ff;
  background: #fff;
  color: #12101e;
  text-align: center;
  font-family: Supercell-Magic;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 148.5%;
  width: 50%;
  margin: 0px auto;
  z-index: 99999;
}

/* .conversion_box p:after {
  content: "";
  width: 50%;
  z-index: -1;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 29.648px;
  border: 1.547px solid #000;
  background: linear-gradient(180deg, #a266d8 0%, #d924f1 100%);
} */
.currency_heading h2 {
  color: #12101e;
  text-align: center;
  font-family: VastagoGrotesk-Regular;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.buttons_box a {
  margin: 50px 10px;
  text-decoration: none;
  color: #12101e;
  padding: 20px 30px;
  border-radius: 100px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 228px;
  display: inline-block;
}

.buttons_box a.eth {
  border: 2px solid #627eea;
  cursor: pointer;
}

.buttons_box a.usd {
  border: 2px solid #26a17b;
  cursor: pointer;
}

.currency_heading h6 {
  color: #12101e;
  text-align: center;
  font-family: VastagoGrotesk-Regular;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
}

.currency_form {
  width: 55%;
  margin: 0px auto;
}

.currency_form input.pay {
  padding: 10px;
  border-radius: 26.5px;
  border: 1px solid #a266d8;
  height: 59px;
}

.currency_form input.recive {
  border-radius: 26.5px;
  border: 1px solid #26a17b;
  height: 59px;
  padding: 10px;
}

.currency_form button {
  text-align: center;
  font-family: Supercell-Magic;
  font-size: 12.416px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 29.648px;
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 10px;
  height: 59px;
}

.currency_form button.connect {
  color: #f4f7ee;
  text-shadow: 0px 1.546875px 0px #14110c;
  border-radius: 29.648px;
  border: 1.547px solid #000;
  background: linear-gradient(180deg, #a266d8 0%, #d924f1 100%);
}

.currency_form button.buy {
  color: #14110c;
  border-radius: 29.648px;
  border: 1.547px solid #000;
  background: #fff;
}

.buy {
  pointer-events: none;
}

.newsletter_bg {
  z-index: 99;
}

.hero-qubyai li a {
  text-transform: uppercase;
  font-family: Supercell-Magic;
}

.logoG {
  width: 90px;
}

/* .hero-main {
  position: relative !important;
}

.hero-main video {
  min-width: 100%;
  position: relative;
  z-index: -1;
} */
.hero-text {
  position: absolute;
  top: 32%;
  left: 3%;
  z-index: 1;
}

.select-header {
  background-color: transparent !important;
  border: none !important;
  color: #ffff;
  font-size: 20px;
}

.lng-h * {
  border: none !important;
  font-family: Supercell-Magic;
}

.game-sec-2 {
  margin-top: 10% !important;
}

@media screen and (max-width: 992px) {
  .header_bg {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .hero-video {
    width: 100%;
    height: 100vh;
    object-fit: fill;
  }

  .buttons_box a {
    margin: 10px 10px;
  }
}

@media screen and (max-width: 1400px) {
  .game_intro {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }
}

@media screen and (max-width: 923px) {
  .road_step h3.bars::after {
    background: transparent;
  }

  .road_step h3.bars.active::after {
    background: transparent;
  }

  .team_box.relative .row {
    justify-content: center;
  }

  div#navbarSupportedContent {
    text-align: center;
    background: #be45e5;
  }

  .d-flex.align-items-center.right_bars ul {
    width: 100%;
  }

  button.navbar-toggler {
    background: #fff;
  }

  .team_small_box {
    padding: 47px 0px;
  }

  .team_box.relative .col-md-3 {
    width: 45%;
  }

  .col-md-6 {
    width: 100%;
  }

  .container-md,
  .container-sm,
  .container {
    max-width: 93%;
  }

  .banner_content {
    width: 100%;
  }

  .banner_content h1 {
    font-size: 44px;
  }

  .Hero_bg {
    height: 725px;
    background-position: top;
  }

  .banner_content h6 {
    font-size: 26px;
  }

  .banner_btns a {
    font-size: 18px;
    margin-right: 10px;
  }

  .anlytics_bl:after {
    right: 0;
  }

  .currency_form {
    width: 70%;
  }

  .road_box .col-md-3 {
    width: 50%;
  }

  .currency_form .mb-5 {
    margin-bottom: 20px !important;
  }

  .currency_form button {
    width: 97% !important;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .into_image.mb-4 {
    text-align: center !important;
  }

  .news_form input {
    width: 100%;
  }

  .main_footer .col-md-6 {
    width: 48% !important;
  }

  .main_footer .col-md-2 {
    width: 0% !important;
  }

  .main_footer .col-md-4 {
    width: 48% !important;
  }

  .counter_box .row {
    justify-content: center !important;
  }

  .play_form .heading_box p {
    font-size: 19px;
  }

  .conversion_box p {
    width: 80%;
  }
}

@media screen and (max-width: 821px) {
  .heading_box.text-center.mb-5 p {
    padding: 0px 20px;
  }

  .Faq_box:before {
    width: 199px;
    height: 197px;
  }

  .Faq_box:after {
    width: 200px;
    height: 200px;
  }

  .team_box.relative .col-md-3 {
    width: 50%;
  }

  .team_small_box {
    background-size: contain;
  }

  .Hero_bg {
    height: 90vh;
  }

  .anayltic .col-md-3 {
    width: 100% !important;
  }

  .banner_content h1 {
    font-size: 45px !important;
  }

  .banner_content {
    width: 100% !important;
  }

  .banner_btns a {
    padding: 10px 30px;
    font-size: 15px;
  }

  .analytic_box h1 {
    font-size: 28px;
  }

  .analytic_box h6 {
    font-size: 22px;
  }

  .heading_box h1 {
    font-size: 35px;
  }

  .play_form .heading_box p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .hero-text {
    top: 35%;
  }

  .game_intro {
    background-size: cover;
  }
}

@media screen and (max-width: 600px) {
  .banner_btns a {
    margin-bottom: 15px;
    padding: 5px 10px;
    font-size: 14px;
  }

  .game_intro_box h2 {

    font-size: 36px;

  }

  .banner_content h6 {
    font-size: 20px;
  }

  .banner_content h1 {
    font-size: 34px !important;
    -webkit-text-stroke: 0.01px #000;
    font-weight: 900;
  }

  .banner_btns.mt-4 {
    margin-top: 8px !important;
  }

  .banner_content {
    padding-top: 0px;
  }

  .anlytics_bl:after {
    background: transparent;
  }

  .analytic_box.mb-4 {
    text-align: center;
  }

  .coin {
    display: none;
  }

  img.mb-2 {
    width: 100%;
  }

  .road_step h3.bars::after {
    display: none;
  }

  .road_box .col-md-3 {
    width: 100%;
  }

  .team_box.relative .col-md-3 {
    width: 100%;
  }

  .team_small_box {
    padding: 48.5px 0px;
  }

  .heading_box h1 {
    font-size: 32px;
  }

  img.Intro {
    width: 100%;
  }

  .join_box .banner_btns a {
    width: 100%;
    margin-bottom: 20px;
    font-size: 8px;
    height: 71px;
    line-height: 71px;
  }

  .Faq_box:after {
    background-image: unset;
  }

  .Faq_box:before {
    background-image: unset;
  }

  .main_footer .col-md-6 {
    width: 100% !important;
  }

  .f_widget {
    margin-bottom: 20px;
  }

  .main_footer .col-md-4 {
    width: 100% !important;
  }

  .copy_txt.d-flex.justify-content-start.align-items-center {
    display: block !important;
    text-align: center;
  }

  .copy_txt.d-flex.justify-content-start.align-items-center p {
    margin-bottom: 20px !important;
  }

  .copy_txt a {
    margin-left: 11px;
  }

  .use_links.text-end {
    text-align: center !important;
    margin-top: 10px !important;
  }
}

.language_dropdown {
  font-size: 14px;
  font-weight: 700 !important;
}

.language_dropdown img {
  border-radius: 9999px !important;
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: top;
}

.language_dropdown_content {
  position: absolute;
  top: 56px;
  left: 24px;
  border-radius: 14px;
  box-shadow: #0000ff;
  font-size: 12px;
  background-color: white;
}

.language_dropdown_content div div:hover {
  background-color: rgb(180, 174, 174);
  cursor: pointer;
}

.country-img {
  height: 20px;
  width: 20px;
}

.social-icons a:hover img {
  /* transform: scale(1.3); */
}

.social-icons a img {
  transition: 300ms;
}

.hero-video {
  object-position: 70% 100%;
}

.slick-slide {
  padding-left: 15px;
}