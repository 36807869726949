@font-face {
  font-family: "VastagoGrotesk-Black";
  src: url("./assets/fonts/VastagoGrotesk-Black.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-Bold";
  src: url("./assets/fonts/VastagoGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-ExtraLight";
  src: url("./assets/fonts/VastagoGrotesk-ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-Heavy";
  src: url("./assets/fonts/VastagoGrotesk-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-Light";
  src: url("./assets/fonts/VastagoGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-Medium";
  src: url("./assets/fonts/VastagoGrotesk-Medium.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-Regular";
  src: url("./assets/fonts/VastagoGrotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-SemiBold";
  src: url("./assets/fonts/VastagoGrotesk-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "VastagoGrotesk-Thin";
  src: url("./assets/fonts/VastagoGrotesk-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Supercell-Magic";
  src: url("./assets/fonts/supercell-magic-webfont.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "VastagoGrotesk-Regular" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* border: 4px solid rebeccapurple; */
}

.ff-supercell {
  font-family: "Supercell-Magic";
}

.nav-item a {
  color: #fff;
  font-size: 14px;
}

.nav-item {
  transition: 300ms;
}

.nav-icons:hover {
  transform: scale(1.2);
}

.butn_stack {
  color: #f4f7ee;
  text-align: center;
  text-shadow: 0px 2.3671875px 0px #14110c;
  font-family: Supercell-Magic;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  background: linear-gradient(180deg, #a266d8 0%, #d924f1 100%);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 50px;
  outline: 2.367px solid #000;
  border-top: 2.367px solid #f7fde7;
}

.right_bars ul li a.nav-link {
  margin: 0px 5px;
}

.header_bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  padding-left: 30px;
  padding-right: 30px;
}

.header_bg a.nav-link:hover {
  color: #46b8be;
}

a.nav-link.active {
  color: #fff !important;
}

.relative {
  position: relative;
}

.news_heading h4 {
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.news_form input {
  width: 380px;
  margin-right: 20px;
  border-radius: 100px;
  border: 1px solid #12101e;
  padding: 14px 24px 14px 34px;
}

.user_imgg {
  position: absolute;
  top: 17px;
  left: 10px;
}

.news_form button {
  border-radius: 100px;
  background: #a266d8;
  padding: 14px 28px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  border: 0px;
}

.main_footer {
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid #cdcdcd;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #cdcdcd;
}

.f_widget p {
  color: #6d6d6d;
  font-family: VastagoGrotesk-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.info_small_box p {
  color: #a266d8 !important;
  font-family: VastagoGrotesk-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.info_small_box {
  margin-right: 15px;
}

.info_small_box a {
  color: #040505;
  text-decoration: none;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.usrful_links h5 {
  color: hsl(272, 59%, 62%);
  font-family: VastagoGrotesk-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.usrful_links a {
  text-decoration: none;
  display: block;
  margin-bottom: 15px;
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.usrful_links a::after {
  content: "";
  background-color: #12101e;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  position: absolute;
  transition: 300ms;
}

.comming-soon-text {
  top: -70%;
  opacity: 0;
}

.dapp:hover .comming-soon-text {
  opacity: 1;
}

.usrful_links a:hover::after {
  width: 100%;
}

.copy_txt a {
  margin-left: 19px;
}

.copy_txt p {
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.use_links a {
  text-decoration: none;
  color: #12101e;
  font-family: VastagoGrotesk-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.btn-hover {
  transition: transform 1s ease;
}

.btn-hover:hover {
  box-shadow: 0px 0px 13px 2px #c7c7c7;

  transform: scale(1.1);
}

.h-telegram:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease;
  color: #229ed9 !important;
}

.h-youtube:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease;
  color: #cd201f !important;
}

.h-twitter:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease;
  color: #1da1f2 !important;
}

/* Pagination */

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.pagination__link {
  padding: 8px 16px;
  margin: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination__link:hover {
  background-color: #eee;
}

.pagination__link--active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination__link--disabled {
  color: #888;
  cursor: not-allowed;
}

.page-item {
  display: inline-block;
}

.page-link {
  padding: 8px 16px;
  margin: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
  text-decoration: none;
  color: #333;
}

.page-link:hover {
  background-color: #f8f9fa;
  text-decoration: none;
}

/* Pagination */

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.policy-heading {
  font-family: "Supercell-Magic";
  font-size: 14px;
}

.policy-content {
  color: #585858;
  font-family: 12px !important;
}

.social-icons a svg:hover {
  transform: scale(1.2);
}

.social-icons a svg {
  transition: 300ms;
}

.social-icons a img:hover {
  transform: scale(1.2);
}

.use_links a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.use_links a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.solana-wrapper {
  background-color: #0c192b;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.presale-wrapper {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  height: 700px;
  max-width: 1320px;
  margin: 100px auto;
  background-size: contain;
  display: flex;
  justify-content: center;
  background-position: center;
  align-items: center;
  background-image: url("./assets/images/minimal-countdown to-sales-instagram-story.jpg");
}

.presale-wrapper a {
  background-color: white;
  color: black;
  border: 2px solid white;
  width: 100%;
  max-width: 200px;
  padding: 12px 10px;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  border-radius: 20px;
  font-weight: bold;
  position: absolute;
  bottom: 50px;
  z-index: 11;
  transition: all 0.3s ease-in-out;
}

.presale-wrapper a:hover {
  background-color: transparent;
  color: white;
}

.team-video-iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
}

@media (max-width: 1280px) {
  .presale-wrapper {
    height: 550px;
    margin: 100px auto;
  }
}

@media (max-width: 1024px) {
  .presale-wrapper {
    height: 450px;
    margin: 60px auto;
  }
}

@media (max-width: 768px) {
  .presale-wrapper {
    height: 350px;
    margin: 60px auto;
  }
  .presale-wrapper a {
    max-width: 150px;
    padding: 12px 10px;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .presale-wrapper {
    height: 180px;
    margin: 10px auto;
    padding-left: 12px;
    padding-right: 12px;
  }
  .presale-wrapper a {
    max-width: 120px;
    padding: 8px 6px;
    font-size: 16px;
    bottom: 10px;
  }
}
